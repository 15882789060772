import React from "react"
import SideBar from "react-sidebar"
import Menu from "./menu"
import MenuOncology from "./menuOncology"
import MenuNew from "./menuNew"
import bmBlue from "../../images/bmbuttonBlue.png"

class SideBarMenu extends React.Component {
  constructor(props) {
    super(props)
    this.state = {
      sidebarOpen: false,
      sidebarpullRight: true,
      toggle: false,
    }

    this.onSetSidebarOpen = this.onSetSidebarOpen.bind(this)
  }
  onSetSidebarOpen(state) {
    this.setState({ sidebarOpen: state.open, toggle: !this.state.toggle })
  }
  toggleMenu() {
    this.setState({
      sidebarOpen: !this.state.sidebarOpen,
      toggle: !this.state.toggle,
    })
  }

  handleKeyDown = ev => {
    // check keys if you want
    if (ev.keyCode === 13) {
      this.focus()
    }
  }

  focus = () => this.ref.focus

  handleRef = component => {
    this.ref = component
  }

  render() {
    return (
      <SideBar
        sidebar={
          this.props.page === "new-home" ? (
            <MenuNew />
          ) : this.props.page === "oncology" ||
            this.props.page === "oncology/contact-us" ? (
            <MenuOncology />
          ) : (
            <Menu page={this.props.page} />
          ) // Menu list
        }
        open={this.state.sidebarOpen}
        onSetOpen={this.onSetSidebarOpen}
        pullRight={this.state.sidebarpullRight}
        styles={{
          sidebar: {
            background: "#fff",
            width: "300px",
            position: "fixed",
            zIndex: "3",
          },
          root: { position: "relative", overflow: "visible" },
          overlay: { backgroundColor: "rgba(0, 0, 0, 0.65)", zIndex: 2 },
          content: {
            position: "relative",
            overflow: "visible",
            cursor: "pointer",
          },
        }}
      >
        <button
          style={{ border: "none", outline: "none", background: "none" }}
          onClick={e => this.toggleMenu(e)}
          onKeyDown={e => this.handleKeyDown(e)}
          className="bm-burger-button float-right"
        >
          <span className="mr-2">Menu</span>
          <img className="img-fluid" src={bmBlue} alt="bmWhite" />
          {/* <img className="img-fluid white-bm" src={bmWhite} alt="bmWhite"/>            */}
        </button>
      </SideBar>
    )
  }
}

export default SideBarMenu
