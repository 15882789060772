import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import logo from "../../images/logo_no-tagline.png"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">
          <span className="display-4 text-white">
            <img className="img-fluid" src={logo} alt="logo" />
          </span>
        </Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/specialties">SPECIALTIES</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/technology">INFORMATION TECHNOLOGY</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="../technology/ERPCRM">ERP/CRM</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="../technology/data">DATA</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/oncology">ONCOLOGY</Link>
      </Nav.Item>
      <Nav.Item>
        <Link to="/contact-us">CONTACT US</Link>
      </Nav.Item>
    </Nav>
  </div>
)
