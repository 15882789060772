import React from "react";
import { Link } from "gatsby";
import SideBarMenu from "../sidebar/sidebar";
import Sticky from "react-sticky-el";
import styl from "./header.module.css";
import { LazyLoadImage } from "react-lazy-load-image-component";
import logo from "../../images/mainLogo.png";
import logoNoTagline from "../../images/logo_no-tagline.png";
import { Nav, Navbar, NavDropdown } from "react-bootstrap";
const Header = ({ page }) => (
  <header>
    <Sticky
      className={styl.stickyWrapper}
      stickyClassName={styl.isSticky}
      stickyStyle={{ transform: "unset", zIndex: "99" }}
    >
      <div className={`header shadow ${styl.header}`}>
        <div className="container">
          <div className="row justify-content-between align-items-center">
            <div className="col-lg-3 col-md-6 col-sm-6 col-6">
              <div className="header-logo">
                <Link to="/technology">
                  <span className="display-4 text-white">
                    <LazyLoadImage
                      className="img-fluid"
                      src={page === "contact-us" ? logoNoTagline : logo}
                      alt="logo"
                    />
                  </span>
                </Link>
              </div>
            </div>
            <div className="col-lg-9 col-md-6 col-sm-6 col-6 text-center">
              <Navbar className="float-right header-navigation">
                <Navbar.Toggle aria-controls="basic-navbar-nav" />
                <Navbar.Collapse id="basic-navbar-nav">
                  <Nav className="mr-auto">
                    <NavDropdown title="About Us" id="basic-nav-dropdown">
                      <Nav>
                        <Link to="/about-us">Our Team</Link>
                      </Nav>
                      <Nav>
                        <Link to="/company-awards">Awards</Link>
                      </Nav>
                      {/* <Nav>
                        <Link to="/peernetwork">PeerNetwork</Link>
                      </Nav> */}
                      <Nav>
                        <Link to="/technology/testimonials/">Testimonials</Link>
                      </Nav>
                      <Nav>
                        <Link to="/charities">Charities</Link>
                      </Nav>
                    </NavDropdown>

                    <NavDropdown title="Candidates" id="basic-nav-dropdown2">
                      <Nav>
                        <Link to="/jobs">Search for Jobs</Link>
                      </Nav>
                      <Nav>
                        <Link to="/technology/benefits">Benefits</Link>
                      </Nav>
                      <Nav>
                        <Link to="/referral-program">Referral Program</Link>
                      </Nav>
                    </NavDropdown>
                    <NavDropdown title="Employers" id="basic-nav-dropdown3">
                      <Nav>
                        <Link to="/about">About</Link>
                      </Nav>
                      <Nav>
                        <Link to="/recruiting-services">
                          Recruiting Services
                        </Link>
                      </Nav>
                      {/* <Nav>
                        <Link to="/payroll-services">Payroll Services</Link>
                      </Nav> */}
                    </NavDropdown>
                    <Nav>
                      <Link to="/news">News</Link>
                    </Nav>
                    <Nav>
                      <Link to="/contact-us">Contact Us</Link>
                    </Nav>
                  </Nav>
                </Navbar.Collapse>
              </Navbar>
              {/*<Link className="head-btn" to="#">
                Find a candidate
              </Link>*/}
              <div className="bmhead">
                <SideBarMenu page={page} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </Sticky>
  </header>
);

export default Header;
