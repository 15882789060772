import React from "react";
import { Link } from "gatsby";
import { Nav, NavDropdown } from "react-bootstrap";
import "./menu.css";
import logo from "../../images/mainLogo.png";
import logoNoTagline from "../../images/logo_no-tagline.png";

export default ({ page }) => {
  return (
    <div className="navigation">
      <Nav className="flex-column">
        <Nav.Item>
          <Link to="/">
            <span className="display-4 text-white">
              <img
                className="img-fluid"
                src={page === "contact-us" ? logoNoTagline : logo}
                alt="logo"
              />
            </span>
          </Link>
        </Nav.Item>

        <Nav.Item>
          <Link to="/">HOME</Link>
        </Nav.Item>

        <NavDropdown title="ABOUT US" id="basic-nav-dropdown">
          <Nav.Item>
            <Link to="/about-us">OUR TEAM</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/company-awards">AWARDS</Link>
          </Nav.Item>
          {/* <Nav.Item>
            <Link to="/peernetwork">PEERNETWORK</Link>
          </Nav.Item> */}
          <Nav.Item>
            <Link to="/technology/testimonials">TESTIMONIALS</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/charities">CHARITIES</Link>
          </Nav.Item>
        </NavDropdown>

        <NavDropdown title="CANDIDATE" id="basic-nav-dropdown2">
          <Nav.Item>
            <Link to="/jobs">SEARCH FOR JOBS</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/benefits">BENEFITS</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/referral-program">REFERRAL PROGRAM</Link>
          </Nav.Item>
        </NavDropdown>

        <NavDropdown title="EMPLOYERS" id="basic-nav-dropdown3">
          <Nav.Item>
            <Link to="/about">ABOUT</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/recruiting-services">RECRUITING SERVICES</Link>
          </Nav.Item>
          <Nav.Item>
            <Link to="/payroll-services">PAYROLL SERVICES</Link>
          </Nav.Item>
        </NavDropdown>

        <Nav.Item>
          <Link to="/news">NEWS</Link>
        </Nav.Item>
        <Nav.Item>
          <Link to="/contact-us">CONTACT US</Link>
        </Nav.Item>
      </Nav>
    </div>
  );
};
