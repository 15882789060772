import React from "react"
import { Link } from "gatsby"
import { Nav } from "react-bootstrap"
import "./menu.css"
import logo from "../../images/logo-oncology.png"

export default () => (
  <div className="navigation">
    <Nav className="flex-column">
      <Nav.Item>
        <Link to="/">
          <span className="display-4 text-white">
            <img className="img-fluid" src={logo} alt="logo" />
          </span>
        </Link>
      </Nav.Item>

      <Nav.Item>
        <a href="/oncology/#about-peersource-oncology">ABOUT US</a>
      </Nav.Item>
      <Nav.Item>
        <a href="/oncology/#our-specialties">OUR SPECIALTIES</a>
      </Nav.Item>
      <Nav.Item>
        <Link to="/oncology/contact-us/">CONTACT US</Link>
      </Nav.Item>
    </Nav>
  </div>
)
